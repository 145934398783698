import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import Index from "@/pages/Index"
import Search from "@/pages/Search"
import Mobile from "@/pages/Mobile"
import MobileSearch from "@/pages/MobileSearch"
import Upload from "@/pages/Upload"
import ManageConfirm from "@/pages/manages/ManageConfirm"
import ManagePreview from "@/pages/manages/ManagePreview"
import ManageUpload from "@/pages/manages/ManageUpload"

const routes = [
    {
        path: "/",
        component: Index
    },
    {
        path: "/search",
        component: Search
    },
    {
        path: "/upload",
        component: Upload
    },
    {
        path: "/mobile",
        component: Mobile
    },
    {
        path: "/mobile/search",
        component: MobileSearch
    },
    {
        path: "/manage/preview",
        component: ManagePreview
    },
    {
        path: "/manage/confirm",
        component: ManageConfirm
    },
    {
        path: "/manage/upload",
        component: ManageUpload
    },
]
const router = new VueRouter({
    routes,
    mode:'history',
})


router.beforeEach((to, from, next) => {
    const isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    if (isMobile && to.path.startsWith('/mobile')) {
        next();
    } else if (isMobile && !to.path.startsWith('/mobile')) {
        next({ path: '/mobile' });
    } else if (!isMobile && to.path.startsWith('/mobile')) {
        next({ path: '/' });
    } else {
        if (to.path.startsWith('/manage')) {
            if (!sessionStorage.getItem('license')) {
                next('/login');
            } else {
                next();
            }
        } else {
            next();
        }
    }
});

export default router