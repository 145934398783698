<template>
    <div class="contain">
        <HeaderMenu></HeaderMenu>
        <UploadFile></UploadFile>
        <BottomMenuCloud></BottomMenuCloud>
    </div>
</template>

<script>
import HeaderMenu from "@/components/HeaderMenu"
import BottomMenuCloud from "@/components/BottomMenuCloud"
import UploadFile from "@/components/UploadFile"

export default {
    components: { HeaderMenu, BottomMenuCloud, UploadFile },
    data() {
        return {
            
        }
    },
    methods: {

    },
    mounted() {
    }
}
</script>

<style scoped>
.contain {
    position: relative;
    background-color: #3A90FF;
}
</style>