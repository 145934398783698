<template>
    <div class="phone-container">
        <a class="cloud-tip" href="https://txc.qq.com/products/497577">网站特别提示注意：当前网站的资料全部来自同学们的热情投稿，若网站资料侵权请联系管理员删除资料。</a>
        <img class="search-logo" src="../assets/logo.png" alt="">
        <div class="search-input">
            <input class="search-input-content" v-model="keyword" type="text" placeholder="请填写搜索资料或者试卷关键词">
            <div class="search-input-submit" @click="$router.replace(`/mobile/search?keyword=${keyword}`)">
                <svg t="1706194878223" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6784" width="20" height="20"><path d="M0 0h1024v1024H0z" fill="#1296db" fill-opacity=".01" p-id="6785"></path><path d="M468.114286 29.257143a438.857143 438.857143 0 0 1 360.448 689.225143l154.477714 154.477714a36.571429 36.571429 0 0 1 0 51.712l-51.712 51.712a36.571429 36.571429 0 0 1-51.712 0l-153.380571-153.307429A438.857143 438.857143 0 1 1 468.114286 29.257143z m0 146.285714a292.571429 292.571429 0 1 0 0 585.142857 292.571429 292.571429 0 0 0 0-585.142857z" fill="#1296db" p-id="6786"></path></svg>
            </div>
        </div>
        <div class="search-menu">
            <span>推荐使用电脑端进行访问</span>
            <a @click="showMenuHotMenu">刷新推荐词</a>
        </div>
 
        <div class="blank-bottom">
            <div class="blank-bottom-hot">
                <span @click="keyword = item" v-for="(item, index) in hotList" :key="index" v-show="item != '空白'">{{ item }}</span>
                <span @click="keyword = item" v-for="(item, index) in hotList" :key="index" v-show="item == '空白'" style="visibility: hidden;">{{ item }}</span>
            </div>
            <div class="blank-bottom-menu">
                <a href="https://www.mslmsxp.com">秒数联盟数学派</a>
                <a href="https://cat.mslmsxp.com">迷你英语单词猫</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            hotList: [
                "数学", "物理", "化学", "英语", "生物", "语文", "高中", "初中", "高考", "中考", "高三", "高二", "高一", "初三", "初二", "初一", "模拟",  "真题", "一模", "二模", "三模", "导数", "几何", "函数", "填空", "选择", "竞赛", "知识", "笔记", "答案"
            ],
            keyword: "",
            launch: false,
            datumList: []
        }
    },
    methods: {
        showMenuHotMenu() {
            this.datumList = []
            this.launch = false
            this.hotList.sort(()=>{	return Math.random() - 0.5 })
        },
    },
    mounted() {
        this.hotList.sort(()=>{	return Math.random() - 0.5; })
        for (let index = 0; index < 10; index++) { this.hotList.push("空白") }
    }
}
</script>

<style scoped>
.phone-container {
    background-color: #3A90FF;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    font-family: "cloud";
    overflow-y: scroll;
    min-width: 360px;
    max-width: 768px;
    margin: 0 auto;
    box-sizing: border-box;
    font-size: 15px;
}

.cloud-tip {
    padding: 10px 20px;
    line-height: 20px;
    background-color: #2B78DE;
    color: #A2C5F4;
    display: -webkit-box;    
    -webkit-box-orient: vertical;    
    -webkit-line-clamp: 2;    
    overflow: hidden;
    height: 40px;
    text-align: justify;
}

.search-logo {
    width: 120px;
    height: 120px;
    display: block;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.search-input {
    display: flex;
    width: calc(100% - 40px);
    background-color: #FFFFFF;
    margin: 0 auto;
    border-radius: 5px;
    overflow: hidden;
}

.search-input-content {
    width: calc(100% - 40px);
    box-sizing: border-box;
    padding: 0 20px;
    height: 40px;
    border-radius: 20px 0 0 20px;
    outline: none;
    border: none;
    font-family: "cloud";
    font-size: 14px;
}

.search-input-submit {
    height: 40px;
    width: 40px;
    background-color: #F3F6FD;
    font-family: "cloud";
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-menu {
    height: 40px;
    display: flex;
    align-items: center;
    color: #C8DFFD;
    padding: 0 20px;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    margin-top: 20px;
}

.search-menu a {
    outline: none;
    height: 40px;
    display: flex;
    align-items: center;
    color: #F3F6FD;
    padding: 0 20px;
    background-color: #2D7BE2;
    border-radius: 5px;
}

.search-tip {
    color: #90A4AE;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.blank-bottom {
    margin-top: 20px;
    width: 100%;
    box-sizing: border-box;
    min-height: calc(100vh - 350px);
    display: flex;
    flex-direction: column;
}

.blank-bottom-hot {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 15px;
    margin-bottom: 10px;
    justify-content: space-between;
    max-height: 210px;
    overflow: hidden;
}

.blank-bottom-hot span {
    font-size: var(--mini-font);
    padding: 0 10px;
    background-color: #C8DFFD;
    margin: 5px;
    height: 25px;
    display: flex;
    align-items: center;
    color: #3A90FF;
    border-radius: 5px;
}

.blank-bottom-menu {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    margin-bottom: 40px;
}

.blank-bottom-menu a {
    background-color: #F3F6FD55;
    width: calc(50% - 10px);
    height: 40px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
    color: #F3F6FD;
    border: 1px solid #F3F6FD;
    border-radius: 5px;
}
</style>