<template>
    <div class="header">
        <div class="header-tip">
            <img @click="$router.replace('/')" class="header-tip-logo" src="../assets/logo.png" alt="">
            <span @click="$router.replace('/')" class="header-tip-title">亿题云资料宝库</span>
        </div>

        <div v-if="loginBoxState" class="search-input">
            <input class="search-input-content" v-model="password" type="text" placeholder="请填写管理员授权码登录管理端">
            <a class="search-input-submit" @click="loginAdmin">登录管理端</a>
        </div>

        <a v-if="!loginBoxState" class="header-button" @click="$router.replace('/upload')">我想投稿资料</a>
        <a v-if="!loginBoxState" class="header-button" @click="copyrightNotice">资料侵权解决方案</a>
        <a v-if="!loginBoxState" class="header-button" target="_blank" href="https://txc.qq.com/products/497577">亿题云反馈空间</a>
        <a v-if="!loginBoxState" class="header-button" target="_blank" href="https://www.mslmsxp.com">数学派官网</a>
        <a v-if="!loginBoxState" class="header-button" target="_blank" href="https://cat.mslmsxp.com">迷你英语单词猫</a>
        <a v-if="!loginBoxState" class="header-button" target="_blank" href="https://blog.willwaking.com">唤醒手腕</a>
        <a class="header-button" @click="changeLoginBox">管理端</a>
    </div>
</template>
c
<script>
import api from '@/api'
export default {
    data() {
        return {
            loginBoxState: false,
            password: ""
        }
    },
    methods: {
        copyrightNotice() {
            this.$alert('当前网站的资料全部来自同学们的热情投稿，若网站资料侵权请联系管理员夜雨星空[QQ:673514839]删除资料。', '资料侵权解决方案', {
                confirmButtonText: '确定'
            });
        },
        changeLoginBox() {
            this.loginBoxState = !this.loginBoxState
        },
        loginAdmin() {
            if (!sessionStorage.getItem('license')) {
                api.cloudDatumManageLogin({ password: this.password }).then(res => {
                    if (res.data.code == 200) {
                        sessionStorage.setItem("license", res.data.data)
                        this.$router.replace("/manage/preview")
                    } else {
                        this.password = "管理员授权码错误"
                    }
                })
            } else {
                this.$router.replace("/manage/preview")
            }
        }
    },
    mounted() {
        if (sessionStorage.getItem('license')) {
            this.password = ""
        }
    }
}
</script>

<style scoped>
.header {
    height: 60px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    background-color: #2B78DE;
    position: sticky;
    top: 0;
    box-sizing: border-box;
    width: 100%;
}

.header-tip {
    margin-right: auto;
    display: flex;
    align-items: center;
}

.header-tip-logo {
    width: 40px;
    height: 40px;
    display: block;
    margin-right: 20px;
    cursor: pointer;
    background-color: #3A90FF;
    border-radius: 5px;
}

.header-tip-title {
    font-size: 20px;
    margin-right: 20px;
    cursor: pointer;
}

.header-button {
    background-color: #FFFFFF;
    color: #3A90FF;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    margin-left: 20px;
    cursor: pointer;
    border-radius: 5px;
}

.header-button:hover {
    background-color: #282C34;
    color: #FFFFFF;
}

.search-input {
    display: flex;
    border-radius: 5px;
    overflow: hidden;
}

.search-input-content {
    width: 400px;
    box-sizing: border-box;
    padding: 0 20px;
    height: 40px;
    outline: none;
    border: none;
    font-family: "cloud";
    font-size: 14px;
}

.search-input-submit {
    height: 40px;
    padding: 0 20px;
    background-color: #3A90FF;
    color: #FFFFFF;
    font-family: "cloud";
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
}

.search-input-submit:hover {
    background-color: #282C34;
    color: #FFFFFF;
}
</style>