<template>
    <div class="header">
        <div class="header-tip">
            <img class="header-tip-logo" @click="$router.replace('/')" src="../assets/logo.png" alt="">
            <span class="header-tip-title" @click="$router.replace('/')">亿题云资料宝库</span>
        </div>
        <div class="search-input">
            <input class="search-input-content" v-model="keyword" type="text" placeholder="请填写你想搜索资料或试卷的关键词！">
            <a class="search-input-submit" :href="`/search?keyword=${keyword}`" target="_blank">即刻搜索</a>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            keyword: ""
        }
    }
}
</script>

<style scoped>
.header {
    height: 60px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    color: #3A90FF;
    background-color: #F1F4FB;
    position: fixed;
    box-sizing: border-box;
    width: 100%;
    position: sticky;
    top: 0;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 10%);
}

.header-tip {
    margin-right: auto;
    display: flex;
    align-items: center;
}

.header-tip-logo {
    width: 40px;
    height: 40px;
    display: block;
    margin-right: 20px;
    cursor: pointer;
    background-color: #3A90FF;
    border-radius: 5px;
}
.header-tip-title {
    font-size: 20px;
    margin-right: 20px;
    cursor: pointer;
}

.search-input {
    display: flex;
    border-radius: 5px;
    overflow: hidden;
}

.search-input-content {
    width: 600px;
    box-sizing: border-box;
    padding: 0 20px;
    height: 40px;
    outline: none;
    border: none;
    font-family: "cloud";
    font-size: 15px;
}

.search-input-submit {
    height: 40px;
    padding: 0 20px;
    background-color: #3A90FF;
    color: #FFFFFF;
    font-family: "cloud";
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 15px;
}

.search-input-submit:hover {
    background-color: #282C34;
    color: #FFFFFF;
}
</style>