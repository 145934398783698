import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://cloud.mslmsxp.com/server',
});

let api = {
    cloudDatumSearch(data) {
        return instance.post("/cloud/datum/search", data);  
    },
    cloudDatumUpload(data) {
        return instance.post("/cloud/datum/upload", data);  
    },
    cloudDatumDownload(data) {
        return instance.get(`/cloud/datum/download?serial=${data.serial}`);  
    },
    cloudDatumManagePage(data) {
        return instance.post("/cloud/manage/datum/select", data);  
    },
    cloudDatumManageLogin(data) {
        return instance.post("/cloud/manage/login", data);  
    },
    cloudDatumManageDelete(data) {
        return instance.post("/cloud/manage/datum/delete", data);  
    },
    cloudDatumManageConfirm(data) {
        return instance.post("/cloud/manage/datum/confirm", data);  
    }
}

export default api