<template>
    <div class="contain">
        <HeaderSearch :keyword="keyword"></HeaderSearch>
        <Loading v-if="loading"></Loading>
        <div v-else class="file-list">
            <div class="search-tip">搜索结果：亿题云资料宝库已经帮助您推荐搜索到 {{ datumList.length }} 种相关资料</div>
            <FileBox v-for="datum in datumList" :key="datum.serial" :datum="datum"></FileBox>
            <div style="clear:both"></div>
        </div>
        <BottomMenuCloud></BottomMenuCloud>
    </div>
</template>

<script>
import HeaderSearch from "@/components/HeaderSearch"
import BottomMenuCloud from "@/components/BottomMenuCloud"
import FileBox from "@/components/FileBox"
import Loading from "@/components/Loading"

import api from "@/api"
export default {
    components: { HeaderSearch, BottomMenuCloud, FileBox, Loading },
    data() {
        return {
            loading: false,
            keyword: "",
            datumList: []
        }
    },
    methods: {
        hightLightFilter() {
            this.datumList.forEach(datum => {
                if (this.keyword) {
                    datum.title = datum.title.replaceAll(this.keyword, `<span style='color: #D00000'>${this.keyword}</span>`)
                }
            })
        }
    },
    mounted() {
        this.keyword = this.$route.query.keyword
        this.loading = true
        api.cloudDatumSearch({ keyword: this.keyword }).then(res => {
            if (res.data.code == 200) {
                this.datumList = res.data.data
                this.hightLightFilter()
                this.loading = false
            }
        })
    },
    updated() {
    }
}
</script>

<style scoped>
.contain {
    position: relative;
    background-color: #F3F6FD;
}

.file-list {
    min-height: calc(100vh - 80px);
    padding: 0 10px;
    padding-top: 10px;
    padding-bottom: 20px;
}

.search-tip {
    color: #90A4AE;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin-top: 10px;
}
</style>