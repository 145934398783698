<template>
    <div class="phone-container">
        <div class="file-list">
            <div class="search-tip">
                <span>亿题云推荐搜索到 {{ datumList.length }} 种相关资料</span>
                <span @click="$router.replace('/mobile')">返回首页</span>
            </div>
            <MobileFileBox device="phone" v-for="datum in datumList" :key="datum.serial" :datum="datum"></MobileFileBox>
            <div style="clear:both"></div>
        </div>
    </div>
</template>

<script>
import api from "@/api"
import MobileFileBox from "@/components/MobileFileBox"
export default {
    components: { MobileFileBox },
    data() {
        return {
            keyword: "",
            loading: false,
            datumList: []
        }
    },
    methods: {
        showMenuHotMenu() {
            this.datumList = []
            this.launch = false
            this.hotList.sort(()=>{	return Math.random() - 0.5 })
        },
        hightLightFilter() {
            this.datumList.forEach(datum => {
                if (this.keyword) {
                    datum.title = datum.title.replaceAll(this.keyword, `<span style='color: #D00000'>${this.keyword}</span>`)
                }
            })
        },
        searchDatum() {
            this.launch = true
            api.cloudDatumSearch({ keyword: this.keyword }).then(res => {
                if (res.data.code == 200) {
                    this.datumList = res.data.data
                    this.hightLightFilter()
                }
            })
        }
    },
    mounted() {
        this.keyword = this.$route.query.keyword
        this.loading = true
        api.cloudDatumSearch({ keyword: this.keyword }).then(res => {
            if (res.data.code == 200) {
                this.datumList = res.data.data
                this.hightLightFilter()
                this.loading = false
            }
        })
    }
}
</script>

<style scoped>
.phone-container {
    background-color: #3A90FF;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    font-family: "cloud";
    overflow-y: scroll;
    min-width: 360px;
    max-width: 768px;
    margin: 0 auto;
    box-sizing: border-box;
    font-size: 15px;
}
.file-list {
    width: 100%;
    padding: 10px;
    background-color: #F3F6FD;
    box-sizing: border-box;
    min-height: calc(100vh);
    padding-bottom: 20px;
}

.search-tip {
    padding: 0 10px;
    color: #627281;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>