import { render, staticRenderFns } from "./FileBox.vue?vue&type=template&id=6d767f43&scoped=true&"
import script from "./FileBox.vue?vue&type=script&lang=js&"
export * from "./FileBox.vue?vue&type=script&lang=js&"
import style0 from "./FileBox.vue?vue&type=style&index=0&id=6d767f43&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d767f43",
  null
  
)

export default component.exports