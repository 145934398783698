<template>
    <div class="search">
        <img class="search-logo" src="../assets/logo.png" alt="">
        <div class="search-input">
            <input class="search-input-content" v-model="keyword" type="text" placeholder="请填写你想搜索资料或试卷的关键词！">
            <div class="search-input-submit" @click="$router.replace(`/search?keyword=${keyword}`)">即刻搜索</div>
        </div>
        <div class="blank-bottom-hot">
            <span @click="keyword = item" v-for="(item, index) in hotList" :key="index" v-show="item != '空白'">{{ item }}</span>
            <span @click="keyword = item" v-for="(item, index) in hotList" :key="hotList.length + index" v-show="item == '空白'" style="visibility: hidden;">{{ item }}</span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            hotList: [
                "数学", "物理", "化学", "英语", "生物", "语文", "高中", "初中", "高考", "中考", "高三", "高二", "高一", "初三", "初二", "初一", "模拟",  "真题", "一模", "二模", "三模", "导数", "几何", "函数", "填空", "选择", "竞赛", "知识", "笔记", "答案"
            ],
            keyword: ""
        }
    },
    mounted() {
        for (let index = 0; index < 20; index++) { this.hotList.push("空白") }
    }
}
</script>

<style scoped>
.search {
    height: calc(100vh - 60px);
    min-height: 640px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #3A90FF;
}

.search-logo {
    width: 240px;
    height: 240px;
    display: block;
    margin-top: 100px;
    margin-bottom: 20px;
}

.search-input {
    display: flex;
}

.search-input-content {
    width: 600px;
    box-sizing: border-box;
    padding: 0 30px;
    height: 60px;
    border-radius: 5px 0 0 5px;
    outline: none;
    border: none;
    font-family: "cloud";
    font-size: 18px;
}

.search-input-submit {
    height: 60px;
    padding: 0 30px;
    background-color: #F3F6FD;
    border-radius: 0 5px 5px 0;
    font-family: "cloud";
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 18px;
}

.search-input-submit:hover {
    background-color: #282C34;
    color: #FFFFFF;
}

.blank-bottom-hot {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 15px;
    margin-bottom: 10px;
    justify-content: space-between;
    max-height: 90px;
    overflow: hidden;
    margin-top: 40px;
}

.blank-bottom-hot span {
    padding: 0 20px;
    background-color: #C8DFFD;
    margin: 5px;
    height: 35px;
    display: flex;
    align-items: center;
    color: #3A90FF;
    cursor: pointer;
    border-radius: 5px;
}
.blank-bottom-hot span:hover {
    background-color: #282C34;
    color: #F3F6FD;
}
</style>